import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Message from '../../enb-styled/Message.js';
import TableListAllCandidates from '../../markdowns/sections/docs/candidates/response-list-all-candidates.md';
import TableRetrieveCandidate from '../../markdowns/sections/docs/candidates/response-retrieve-candidate.md';
import TableCreateCandidate from '../../markdowns/sections/docs/candidates/response-create-candidate.md';
import TableUpdateCandidate from '../../markdowns/sections/docs/candidates/response-update-candidate.md';
import TableMergeCandidates from '../../markdowns/sections/docs/candidates/response-merge-candidates.md';
import TableConvertCandidate from '../../markdowns/sections/docs/candidates/response-convert-candidate-to-employee.md';
export const _frontmatter = {
  "title": "Candidates"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Candidates`}</h1>
    <h2>{`Definition`}</h2>
    <p>{`A Candidate is an individual who is applying for a job at your organisation.`}</p>
    <p>{`Once a Candidate is created, the following information can be captured:`}</p>
    <ul>
      <li parentName="ul">{`Their personal details.`}</li>
      <li parentName="ul">{`Evidence of the training and qualifications they need for the job they’re applying for.`}</li>
      <li parentName="ul">{`Interview and shortlist details.`}</li>
      <li parentName="ul">{`Onboarding information and documents, such as tax details, fair work information statement, bank details, etc`}</li>
    </ul>
    <p>{`At the present moment, the enableHR API exposes the personal details of Candidates.`}</p>
    <p>{`Candidates can enter this information themselves via our self-service portal (eSS). Captured data can (or not) go through a staged approval process. `}</p>
    <p>{`Once they have completed the onboarding process, candidates are converted into an employee.`}</p>
    <p>{`enableHR API current exposure for Candidates:`}</p>
    <ul>
      <li parentName="ul">{`Actions:`}
        <ul parentName="li">
          <li parentName="ul">{`List existing Candidates`}</li>
          <li parentName="ul">{`Create, retrieve and update a Candidate`}</li>
          <li parentName="ul">{`Merge two Candidates together`}</li>
          <li parentName="ul">{`Convert a Candidate to an Employee`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`Fields:`}
        <ul parentName="li">
          <li parentName="ul">{`Personal information (excluding custom fields)`}</li>
        </ul>
      </li>
    </ul>
    <div id="list-all-candidates">
      <h2>{`List all Candidates`}</h2>
      <p>{`Use this action to return a filtered list of all candidates in an Account.`}</p>
      <Message mdxType="Message">
Candidates are individuals who are applying for jobs. If they are successful, they can be converted to Employees.
        <br />
Candidates are created and stored in Branches.
        <br />
Candidates are not billed in enableHR, as opposed to Employees, Contractors and Volunteers.
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/candidates/list-candidates.png" alt="List Candidates" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListCandidates/"
                }}>{`GET: List Candidates`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableListAllCandidates mdxType="TableListAllCandidates" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-candidates.png" alt="enableHR Candidates" />
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-candidate-file-1.png" alt="enableHR Candidate" style={{
          "maxWidth": "50%"
        }} />
      </div>
    </div>
    <h2>{`Retrieve a Candidate`}</h2>
    <p>{`Use this action to retrieve the details of a candidate.`}</p>
    <Message mdxType="Message">
Candidates are individuals who are applying for jobs. If successful, they are then converted to Employees.
      <br />
Candidates can use our Self-Service portal (eSS) to capture a lot of information about themselves.
      <br />
Should a Candidate be converted to an Employee, this information will be copied across to the Employee record.
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/retrieve-a-candidate.png" alt="Retrieve Candidate" />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveCandidate/"
              }}>{`GET: Retrieve Candidate`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account.`}<br /><a parentName="td" {...{
                "href": "#list-all-candidates"
              }}>{`Candidate ID`}</a>{` - Unique identifier of the Candidate`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveCandidate mdxType="TableRetrieveCandidate" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-employee-details-1.png" alt="enableHR Employee Details" style={{
        "maxWidth": "70%"
      }} />
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-employee-details-2.png" alt="enableHR Employee Details" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Create a Candidate`}</h2>
    <p>{`Use this action to create a new Candidate.`}</p>
    <Message mdxType="Message">
Candidates are individuals who are applying for jobs. If successful, they are then converted to Employees.
      <br />
Candidates can use our Self-Service portal (eSS) to capture a lot of information about themselves.
      <br />
Should a Candidate be converted to an Employee, this information will be copied to the Employee record.
      <br />
Candidates are created in branches. The ‘Branch ID’, the ‘First Name’ and ‘Last Name’ of the Candidates, are the only mandatory fields when creating a Candidate.
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/create-a-candidate.png" alt="Create Candidate" />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/CreateCandidate/"
              }}>{`POST: Create Candidate`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account.`}<br /><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#list-branches"
              }}>{`Branch ID`}</a>{` - Unique identifier of the Branch`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableCreateCandidate mdxType="TableCreateCandidate" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-candidate-details-1.png" alt="enableHR Candidate Details" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-candidate-details-2.png" alt="enableHR Candidate Details" style={{
        "maxWidth": "90%"
      }} />
    </div>
    <h2>{`Update a Candidate`}</h2>
    <p>{`Use this action to update a Candidate.`}</p>
    <Message mdxType="Message">
Most of the candidate fields can be updated using this action. However it cannot be used to move a candidate from one branch to another, you need to use the specific “Transfer a Candidate” action for this. 
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/update-a-candidate.png" alt="Update Candidate" />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/UpdateCandidate/"
              }}>{`PUT: Update Candidate`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account.`}<br /><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#list-branches"
              }}>{`Branch ID`}</a>{` - Unique identifier of the Branch.`}<br /><a parentName="td" {...{
                "href": "#list-all-candidates"
              }}>{`Candidate ID`}</a>{` - Unique identifier of the Candidate.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableUpdateCandidate mdxType="TableUpdateCandidate" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-candidate-details-3.png" alt="enableHR Candidate Details" style={{
        "maxWidth": "48%"
      }} className="m-1" />
    <img src="/images/docs/candidates/enablehr-candidate-details-4.png" alt="enableHR Candidate Details" style={{
        "maxWidth": "48%"
      }} />
    </div>
    <h2>{`Merge two Candidates`}</h2>
    <p>{`Use this action to merge two Candidates together.`}</p>
    <Message mdxType="Message">
This function is generally used when an applicant has applied for multiple positions, which means there will be  more than one Candidate records created for the same person. Merging these records will allow for all the documentation to be combined in the one Candidate rather than spread across many duplicate records. All personal and contact details of the Candidate (source)  will be merged into the Candidate (destination)  during this process.  Once the merge is complete, the source Candidate will be deactivated.
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/merge-candidates.png" alt="Merge Candidates" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/MergeCandidates/"
              }}>{`POST: Merge Candidates`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account.`}<br /><a parentName="td" {...{
                "href": "#list-all-candidates"
              }}>{`Candidate ID`}</a>{` - Unique identifier of the Candidate.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableMergeCandidates mdxType="TableMergeCandidates" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-merge-records.png" alt="enableHR Merge Records" />
    </div>
    <h2>{`Convert a Candidate into an Employee`}</h2>
    <p>{`Use this action to convert a Candidate into an Employee.`}</p>
    <Message mdxType="Message">
All the personal details of the Candidate will be copied across to the Employee, as well as all the Candidate’s documents and notes. Any incomplete checklist that was opened against the Candidate record will be archived.
      <br />
To convert a Candidate, the only mandatory field is the Commencement Date of employment. Once the conversion is complete, the status of the Candidate will become ‘Converted’ and the Candidate won’t appear in the active list of Candidates anymore.
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/convert-candidate-into-employee.png" alt="Convert Candidate into Employee" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/ConvertCandidateToEmployee/"
              }}>{`POST: Convert Candidate to Employee`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account.`}<br /><a parentName="td" {...{
                "href": "#list-all-candidates"
              }}>{`Candidate ID`}</a>{` - Unique identifier of the Candidate.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableConvertCandidate mdxType="TableConvertCandidate" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/candidates/enablehr-create-employee-from-candidate.png" alt="enableHR Create Employee from Candidate" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      